import React, { useState, useEffect, useContext } from 'react';
import './styles.css';
import '../../App.css';

function Obrigado({ history }) {
  const [buttonVisible, setButtonVisible] = useState(true);

  //Ao abrir a tela
  useEffect(() => {
    /*setTimeout(() => {
      setButtonVisible(true);
      //history.push('/creator/dashboard');
    }, 10000);*/
    //SEND EVENT CONVERSION TO GOOGLE ADS
    //window.gtag('event', 'conversion', {'send_to': 'AW-10868882288/T6hiCPyf_KkDEPD2174o'});

    //SEND EVENT CONVERSION TO GOOGLE Tag Manegar
    //dataLayer.push({event: "conversion"});

    //SEND EVENT CONVERSION TO FACEBOOK PIXEL
    //fbq('track', 'Purchase');
    //fbq('track', 'Purchase', {currency: "USD", value: 30.00});
  }, []);

  return (
  <>
    <div className="container v-center">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/> <br/>
          <div className="col-12">
            <h1 id="logo">SMART-IRIS.</h1>  
          </div> 
          <br/>
          <h3>Obrigado, o seu pagamento está sendo processado!</h3>
          <br/><br/>
          <p>Você receberá todas as orientações e as atualizações do seu pedido via e-mail!</p>
          <p>Qualquer dúvida entre em através do e-mail: <a href="mailto:contato@smartiris.com.br">contato@smartiris.com.br</a></p>
          <p>Ou através do nosso suporte via <a href="http://meuwhats.cf/smart-iris">WhatsApp</a>!</p>
        </div> 
        <div className="">
          <br /> <br />
          { buttonVisible ?
            <div className="row">
              <div className="col-12 text-center">
                <a href="./" className="btn btn-default">
                  <i className="fa fa-reply fa-lg"> </i>
                  <span style={{marginLeft: 15, marginRight: 15}}> Voltar </span>
                </a>
              </div>
            </div>
          : <></> }
          <br/> <br/>
        </div>
      </div>
    </div>
</>);
}

export default Obrigado;
