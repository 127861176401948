import React, { useEffect, useState } from 'react';
import Routes from './routes';
import './App.css';
import { ThemeContext, themes } from './contexts/ThemeContext';

function App() {
  const [theme, setTheme] = useState(themes.dark);

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.documentElement.setAttribute("data-theme", "light");
        break;
      case themes.dark:
        document.documentElement.setAttribute("data-theme", "dark");
        break;
      default:
        document.body.classList.remove('white-content');
        break;
    }
  }, [theme]);

  function changeTheme(theme) {
    setTheme(theme);
  }

  return (
    <ThemeContext.Provider 
      value={{ theme: theme, setTheme: changeTheme }}
    >
      <Routes />
    </ThemeContext.Provider>
  );
}

export default App;
