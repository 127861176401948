//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { browserName, browserVersion } from "react-device-detect";
import { FaWhatsapp } from "react-icons/fa";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import imgHome from '../../assets/images/produto-3-pecas-black.png';
import imgIridophoto from '../../assets/images/iridophoto+suporte.PNG';
import imgIridophotoReal from '../../assets/images/iridophotoESuporte.png';
import imgIridophotoRealPRO from '../../assets/images/iridophotoESuportePRO.png';
import imgFreteGratis from '../../assets/icons/frete-gratis-sul.png';
import off60 from '../../assets/icons/60off.png';
import off40 from '../../assets/icons/40off.png';
import off20 from '../../assets/icons/20off.png';
import iconGarantia from '../../assets/icons/garantia-7-dias.png';
import lineDivider from '../../assets/icons/line-divider.png';
import lineDividerRight from '../../assets/icons/line-divider-right.png';
import './styles.css';
import '../../App.css';

Modal.setAppElement(document.getElementById('root'));

export default function Index({ history }) {
  const activeTab = history.location.pathname;
  const isPro = activeTab === '/smart-iris-pro';
  const [loading, setLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const urlKitPadrao = isPro ? "https://ev.braip.com/checkout/plawdzv2/chex8rjm" : "https://ev.braip.com/checkout/pla8v4nw/chex8rjm"; //"https://ev.braip.com/checkout/pla4g6z0/cherv7g8"
  const urlKitPlus = isPro ? "https://ev.braip.com/checkout/pla41v8k/chex8rjm" : "https://ev.braip.com/checkout/pla9e04q/chex8rjm";

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };
  const imagesGallery = [ 
    {
      original: require('../../assets/images/capa.jpg'),
      thumbnail: require('../../assets/images/capa.jpg'),
      embedUrl: isPro ? 'https://www.youtube.com/watch?v=4lLt4ge6z6A' : 'https://www.youtube.com/watch?v=X0RkgmR-o-4',
      renderItem: (item) => renderVideo(item),
      description: 'Video de Unboxing',
    },
    {
      original: isPro ? require('../../assets/images/iridophotoESuportePRO.png') : require('../../assets/images/galeria01.png'),
      thumbnail: isPro ? require('../../assets/images/iridophotoESuportePRO.png') : require('../../assets/images/galeria01.png'),
      description: 'Iridophoto com Suporte',
    },
    {
      original: isPro ? require('../../assets/images/galeria02PRO.png') : require('../../assets/images/galeria02.png'),
      thumbnail: isPro ? require('../../assets/images/galeria02PRO.png') : require('../../assets/images/galeria02.png'),
      description: 'Iridophoto Acoplado ao Smartphone',
    },
    !isPro && {
      original: require('../../assets/images/galeria05.png'),
      thumbnail: require('../../assets/images/galeria05.png'),
      description: 'Iridophoto com Suporte',
    },
    /*{
      original: require('../../assets/images/caixa01.jpg'),
      thumbnail: require('../../assets/images/caixa01.jpg'),
      description: 'Caixa do SMART-IRIS',
    },*/
    {
      original: isPro ? require('../../assets/images/caixa02PRO.png') : require('../../assets/images/caixa02.jpeg'),
      thumbnail: isPro ? require('../../assets/images/caixa02PRO.png') : require('../../assets/images/caixa02.jpeg'),
      description: 'Caixa do SMART-IRIS',
    },
    {
      original: isPro ? require('../../assets/images/kitplusPRO.png') : require('../../assets/images/Kitplus.jpg'),
      thumbnail: isPro ? require('../../assets/images/kitplusPRO.png') : require('../../assets/images/Kitplus.jpg'),
      description: 'SMART-IRIS + Case + Tripé Mesa',
    },
    !isPro && {
      original: require('../../assets/images/caixaetripe.jpeg'),
      thumbnail: require('../../assets/images/caixaetripe.jpeg'),
      description: 'SMART-IRIS + Tripé Mesa',
    },
    /*{
      original: require('../../assets/images/ledFrontal.JPG'),
      thumbnail: require('../../assets/images/ledFrontal.JPG'),
      description: 'Led frontal acesso',
    },*/
    !isPro && {
      original: require('../../assets/images/iridophotoLed.jpg'),
      thumbnail: require('../../assets/images/iridophotoLed.jpg'),
      description: 'Led traseiro acesso',
    },
    !isPro &&  {
      original: require('../../assets/images/cameraOlho01.JPG'),
      thumbnail: require('../../assets/images/cameraOlho01.JPG'),
      description: 'Captura da foto',
    },
    !isPro && {
      original: require('../../assets/images/cameraOlho02.JPG'),
      thumbnail: require('../../assets/images/cameraOlho02.JPG'),
      description: 'Captura da foto',
    },
    {
      original: require('../../assets/images/olho01.jpeg'),
      thumbnail: require('../../assets/images/olho01.jpeg'),
      description: 'Foto Olho',
    },
    {
      original: require('../../assets/images/olho02.jpeg'),
      thumbnail: require('../../assets/images/olho02.jpeg'),
      description: 'Foto Olho',
    },
    {
      original: require('../../assets/images/olho03.jpg'),
      thumbnail: require('../../assets/images/olho03.jpg'),
      description: 'Foto Olho',
    },
    {
      original: require('../../assets/images/olho04.jpg'),
      thumbnail: require('../../assets/images/olho04.jpg'),
      description: 'Foto Olho',
    }
  ].filter(Boolean)

  function renderVideo(item) {
    return (
      <div className='video-wrapper'>
        <iframe className='video-galery' width="100%" height="100%" src={isPro ? 'https://www.youtube.com/embed/4lLt4ge6z6A' : "https://www.youtube.com/embed/X0RkgmR-o-4"} title="Unboxing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
      </div>
    );
  }

  useEffect(() => {
    const onScroll = e => {
      //console.log(`${browserName} ${browserVersion}`);
      if(browserName === "Mobile Safari"){
        setScrollTop(e.target.body.scrollTop);
        //console.log(e.target.body.scrollTop)
      } else {
        setScrollTop(e.target.documentElement.scrollTop);
        //console.log(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    //If user leave page 
    /*document.addEventListener("mouseleave", (event) => {  
      if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) { 
        setModalShow(true);
      }  
    });
    setTimeout(() => {
      setModalShow(true);
    }, 10000);*/
  }, []);

  useEffect(() => {
    if(history.location.pathname === '/'){
      window.location = '/smart-iris-pro'
    }
  }, []);

  const tabs = [
    { id: '0', text: 'Versão PRO', redirect: '/smart-iris-pro'},
    { id: '1', text: 'Geração 1', redirect: '/smart-iris-g1' },
  ];


  const TabBar = ({ tabs }) => {
    return (
        tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-bar ${tab.redirect === activeTab ? 'active' : ''}`}
            onClick={() => window.location = tab.redirect}
          >
            {tab.text}
          </div>
        ))
    );
  };

  
  return (
    <div id="homeIndex">
      {scrollTop < 150 ?
        <a href="#bodyPlans" onClick={() => {}} className="text-center infoTop">
          <b>FRETE GRÁTIS</b> para a região <b>SUL</b> e <b>SUDESTE</b> | <b>DESCONTO</b> de até <b>R$ 35,00</b> para as demais regiões!
          {/*<b>BLACK FRIDAY!</b> Aproveite <b>+ 15% de DESCONTO</b> com o cupom <b>BLACKFRIDAY</b>!*/}
        </a>
      : <></>}
      {toggleMenu ?
        <div id="menu">
          <ul className="text-center">
            <li><a href="#bodyKnow" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Conheça </span>
            </a></li>
            <li><a href="#bodyGalery" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Detalhes </span>
            </a></li>
            <li><a href="#bodyPlans" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Kits </span>
            </a></li>
            <li><a href="https://web.whatsapp.com/send/?phone=5555997190882&text=Ol%C3%A1,%20gostaria%20de%20falar%20sobre%20o%20SMART-IRIS" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Suporte </span>
            </a></li>
            {/*<li><a href="/login">
              <span> Entrar </span>
            </a></li>
            <li><a href="#bodyPlans" className="btn btn-default" style={{fontSize: 25}}>
              <span> Criar Conta </span>
            </a></li>*/}
          </ul>
        </div>
      : <></> }
      <div id="navbar" className={scrollTop > 150 ? "onScroll" : ""}>
        <div className="container">
          <div className="row v-center">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 logo">
              <h1 onClick={() => window.location = '/smart-iris-pro'}>SMART-IRIS</h1>  
            </div>
            <div className="col-lg-8 text-right hidden-xs hidden-sm hidden-md" style={{marginTop: 40}}>
              <a href="#bodyKnow">
                <span> Conheça </span>
              </a>
              <a href="#bodyGalery">
                <span> Detalhes </span>
              </a>
              <a href="#bodyPlans">
                <span> Kits </span>
              </a>
              <a href="https://web.whatsapp.com/send/?phone=5555997190882&text=Ol%C3%A1,%20gostaria%20de%20falar%20sobre%20o%20SMART-IRIS">
                <span> Suporte </span>
              </a>
              {/*<a href="/login">
                <span> Entrar </span>
              </a>
              <a href="#bodyPlans" className="btn btn-default" style={{marginTop: -5}}>
                <span> Criar Conta </span>
              </a>*/}
            </div>
            <div className="col-6 col-sm-6 col-md-8 text-right visible-xs visible-sm visible-md">
              <button type="button" className="btn btn-default btnMenu" onClick={() => setToggleMenu(!toggleMenu)}>
                {toggleMenu ?
                  <i className="fa fa-times fa-lg"> </i>
                : 
                  <i className="fa fa-bars fa-lg"> </i> 
                } 
              </button>
            </div>
          </div>
          <div className="tabs row">
            <TabBar tabs={tabs} />
          </div>
        </div>
      </div>
      <div className="bodyIndex v-center">
        <div className="container">
          <div className="row v-center">
            <div className="col-md-5 col-12">
              <h1>{isPro ? "Versão PRO, com AJUSTE manual de INTENSIDADE e POSCIONAMENTO dos LEDs!": "1ª Geração, IDEAL para quem está COMEÇANDO e não abre mão da QUALIDADE!"}</h1>
              <p>  </p>
              <div className="hidden-xs">
                <br /><br />
                <div className="row">
                  <div className="col-md-6 col-12">
                    <a href="#bodyPlans" className="btn btn-default-outline col-12">
                      <i className="fa fa-dollar-sign fa-lg" style={{marginRight: 5}}> </i>
                      Comprar Agora
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12 text-center">
              <img src={isPro? imgIridophotoRealPRO : imgIridophotoReal} width="100%"/>
              <div className="shadowProduct"></div>
            </div>
            {/*<div className="col-12 text-center">
              <div className="visible-xs">
                <br /><br />
                <a href="#bodyPlans" target="_Blank" className="btn btn-default-outline col-12">
                  <i className="fa fa-rocket fa-lg"> </i>
                  <span style={{marginLeft: 5}}> Começar Agora </span>
                </a>
                <br /><br /><br />
              </div>
            </div>*/}
          </div>
          {/*<div style={{ backgroundImage: `url("${lineDivider}")` }} className="lineDivider"></div>*/}
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Escolha um kit e tenha em mãos uma experiência completa!</h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Escolher Kit </span>
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyKnow">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Conheça!</h1>
              <span>Veja o que podemos lhe oferecer!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-7 order-2 order-md-1 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Afinal, o que é o SMART-IRIS?</h4>
              <p>O SMART-IRIS é um equipamento óptico para iridologia, que foi desenvolvido com o intuito de facilitar a obtenção de imagens nítidas dos olhos para análises iridológicas, fornecendo a praticidade de ser acoplado a qualquer smartphone.</p>
              <p>Buscamos oferecer uma solução simples e robusta, que possa colocar a sua disposição a tecnologia necessária, para lhe auxiliar da melhor maneira no seu dia a dia! </p>
              {isPro && <p>E agora estamos lançando a versão PRO, que visa atender as necessidades com ainda mais excelência, fornecendo tecnologias que permitam ao profisional obter imagens ainda melhores.</p>}
              <p>Confira na sequência todos os detalhes que o SMART-IRIS tem para lhe oferecer! </p>
            </div>
            <div className="col-12 col-md-5 order-1 order-md-2 text-center">
              <img src={isPro ? require('../../assets/images/caixa02PRO.png') : require('../../assets/images/caixa02.png')} width="100%"/>
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-7 order-1 order-md-1 text-center">
              <img src={isPro? imgIridophotoRealPRO : imgIridophotoReal} width="100%"/>
            </div>
            <div className="col-12 col-md-5 order-2 order-md-2">
              <div className="text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>Características</h4>
              </div>
              <p><i className="iconCheck">✔ </i> Lente de alta qualidade;</p>
              <p><i className="iconCheck">✔ </i> Iluminação interna;</p>
              <p><i className="iconCheck">✔ </i> Iluminação ajustavel (Íris clara/escura);</p>
              {isPro && <p><i className="iconCheck">✔ </i>Iluminação com AJUSTE de INTENSIDADE;</p> }
              {isPro && <p><i className="iconCheck">✔ </i>Variações de 90° no POSICIONAMENTO dos LEDs;</p> }
              <p><i className="iconCheck">✔ </i> Suporte ajustável para smartphone (Universal);</p>
              <p><i className="iconCheck">✔ </i> Material leve e resistênte (Polímero ABS);</p>
              <p><i className="iconCheck">✔ </i> Produzido com tecnologia FDM;</p>
              <p><i className="iconCheck">✔ </i> Pilha de 12v (Inclusa);</p>
              <p><i className="iconCheck">✔ </i> Garantia de 1 ano;</p>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Que tal escolher um kit agora mesmo? </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Ver Kits </span>
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyGalery">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Galeria</h1>
              <span>Confira o produto em detalhes!</span>  
              <br /><br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <ImageGallery 
                items={imagesGallery} 
                //useBrowserFullscreen={false} 
                //showFullscreenButton
                showPlayButton={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Que tal escolher um kit agora mesmo? </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Ver Kits </span>
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyPlans">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Kits</h1>
              <span>Confira as opções disponíveis!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5 offset-md-1 text-center">
              <img src={off20} className="imgDiscount"/>
              <div className="itemPlan active text-center">
                <div className="text-center">
                  <ImageGallery 
                    items={[
                      /*{
                        original: imgIridophotoReal,
                        thumbnail: imgIridophotoReal
                      },*/
                      {
                        original: isPro ? require('../../assets/images/caixa02PRO.png') : require('../../assets/images/caixa02.png'),
                        thumbnail: isPro ? require('../../assets/images/caixa02PRO.png') : require('../../assets/images/caixa02.png')
                    }]} 
                    //useBrowserFullscreen={false} 
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={false}
                    showNav={false}
                  />
                   {/*<img src={imgIridophotoReal} width="100%"/>*/}
                  <p className="title">Kit Padrão</p>
                  {/*<p className="description">Iridophoto SMART-IRIS com Suporte para Smartphone</p>*/}
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Iridophoto SMART-IRIS {isPro ? 'PRO' : ''}</li> 
                  <li><i>✔</i> Suporte para Smartphone</li> 
                  <li><i>✔</i> 1 ano de garantia</li>
                  <div style={{marginTop: 10}}>
                    <div>✚ Bônus:</div>
                    <div className="text-center">
                      <a href="http://e-iris.com.br" target="_Blank">
                        <h1 style={{marginBottom: 0}}>e-iris.</h1>
                      </a>
                      <div>6 meses de Acesso Premium</div>
                      <div>✚</div>
                      <img src={imgFreteGratis} className="imgFreteGratis" width="100%"/>
                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">{isPro ? '1.490,00' : '1.190,00'}</span> ✚ R$ <span className="oldPrice">402,00</span> (e-iris)
                    </div>
                    <div className="text-center">
                     de R$ <span className="oldPrice" style={{fontSize: 28}}>{isPro ? '1.892,00' : '1.592,00'}</span> por:
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal"> {isPro ? '1.190,00' : '980,00'} </span></strong>
                    </div>
                  </li>
                </ul>
                <a href={urlKitPadrao} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Kit </span>
                </a>
                {/*<div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Entregas a partir do dia 15 de Agosto de 2022!</span>
                </div>*/}
              </div>
            </div>
            <div className="col-12 col-md-5 text-center">
              <img src={off20} className="imgDiscount"/>
              <div className="itemPlan text-center">
                {/*<div className="emBreve">
                  <img src="http://paroquiasaomiguelrp.com.br/wp-content/uploads/2020/06/em-breve_aguarde_sm.png" className="imgEmBreve"/>
                </div>*/}
                <div className="text-center">
                  <ImageGallery 
                    items={[
                      /*{
                        original: imgIridophotoReal,
                        thumbnail: imgIridophotoReal
                      },*/
                      {
                        original: isPro ? require('../../assets/images/kitplusPRO.png') : require('../../assets/images/kitplus.png'),
                        thumbnail: isPro ? require('../../assets/images/kitplusPRO.png') : require('../../assets/images/kitplus.png'),
                    }]} 
                    //useBrowserFullscreen={false} 
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={false}
                    showNav={false}
                    style={{maxHeight: 200}}
                  />
                  <p className="title">Kit Plus</p>
                  {/*<p className="description">Iridophoto SMART-IRIS!</p>*/}
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Iridophoto SMART-IRIS {isPro ? 'PRO' : ''}</li> 
                  <li><i>✔</i> Suporte para Smartphone </li> 
                  <li><i>✔</i> Suporte Articulado para Mesa</li> 
                  <li><i>✔</i> Case para Transporte</li> 
                  <li><i>✔</i> 1 ano de garantia</li>
                  <div style={{marginTop: 10}}>
                    <div>✚ Bônus:</div>
                    <div className="text-center">
                      <a href="http://e-iris.com.br" target="_Blank">
                        <h1 style={{marginBottom: 0}}>e-iris.</h1>
                      </a>
                      <div>1 ano de Acesso Premium</div>
                      <div>✚</div>
                      <img src={imgFreteGratis} className="imgFreteGratis" width="100%"/>
                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">{isPro ? '1.890,00' : '1.490,00'}</span> ✚ R$ <span className="oldPrice">564,00</span> (e-iris)
                    </div>
                    <div className="text-center">
                     de R$ <span className="oldPrice" style={{fontSize: 28}}>{isPro ? '2.454,00' : '2.054,00'}</span> por:
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal">{isPro ? '1.390,00' : '1.190,00'}</span></strong>
                    </div>
                  </li>
                </ul>
                <a href={urlKitPlus} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Kit </span>
                </a>
                {/*<div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Entrega em até 15 dias úteis</span>
                </div>*/}
              </div>
            </div>
            {/*<div className="col-12 col-md-4 text-center">
              <img src={off60} className="imgDiscount"/>
              <div className="itemPlan text-center">
                <div className="text-center">
                  <img src={imgIridophotoReal} width="100%"/>
                  <p className="title">Kit Padrão</p>
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Iridophoto SMART-IRIS;</li> 
                  <li><i>✔</i> Suporte para Smartphone;</li> 
                  <li><i>✔</i> 1 ano de garantia</li>
                  <div style={{marginTop: 10}}>
                    <div>✚ Bônus:</div>
                    <div className="text-center">
                      <a href="http://e-iris.com.br" target="_Blank">
                        <h1 style={{marginBottom: 0}}>e-iris.</h1>
                      </a>
                      <div>6 meses de Acesso Premium</div>
                      ✚
                      <img src={imgFreteGratis} width="100%"/>

                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">1.490,00</span> ✚ R$ <span className="oldPrice">702,00</span> (e-iris)
                    </div>
                    <div className="text-center">
                     de R$ <span className="oldPrice" style={{fontSize: 28}}>2.192,00</span> por:
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal"> 980,00 </span></strong>
                    </div>
                  </li>
                </ul>
                <a href={urlKitPadrao} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Kit </span>
                </a>
                <div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Entrega em até 15 dias úteis</span>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <div id="bodyWarranty">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Política de Devolução!</h1>
              <span>Você terá 7 dias para solicitar a devolução do produto!</span>  
              <br /><br />
            </div>
          </div>
          <div className="row v-center">
            <div className="col-12 col-md-3 text-center">
              <img src={iconGarantia} width="200"/>
            </div>
            <div className="col-12 col-md-6 text-center">
              <p>Se em até 7 dias da compra do produto, você não estiver satisfeito, basta entrar em contato solicitando o cancelamento e devolução do produto, bem como o reembolso de todo o seu dinheiro, sem qualquer questionamento ou burocracia.</p> 
              <p>O nosso compromisso é com a sua satisfação!</p>  
            </div>
            <div className="col-12 col-md-3 text-center hidden-xs">
              <img src={iconGarantia} width="200"/>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-4 text-center">
              <div className="logo">
                <h1 onClick={() => window.location = '/smart-iris-pro'}>SMART-IRIS</h1>
                <p>Canasvieiras, Florianópolis - SC</p>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="row" style={{paddingTop: 20}}>
                <div className="col-12 col-md-6 text-center">
                  <p><a href="#bodyKnow"><span> Conheça </span></a></p>
                  <p><a href="#bodyGalery"><span> Detalhes </span></a></p>
                  <p><a href="./politicas/privacidade" target="_blank"><span> Políticas de Privacidade </span></a></p>
                </div>
                <div className="col-12 col-md-6 text-center">
                  <p><a href="#bodyPlans"><span> Kits </span></a></p>
                  <p><a href="https://web.whatsapp.com/send/?phone=5555997190882&text=Ol%C3%A1,%20gostaria%20de%20falar%20sobre%20o%20SMART-IRIS"><span> Suporte </span></a></p>
                  <p><a href="./politicas/termos" target="_blank"><span> Termos de Uso </span></a></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={require('../../assets/images/seloSSL.png')} width="100%" style={{maxWidth: 220, marginBottom: 5}}/>
              <img src={require('../../assets/images/seloCreditCard.png')} width="100%" style={{maxWidth: 220, background: "rgba(112, 112, 112, 0.45)", padding: 8, borderRadius: 30}}/>
            </div>
          </div>
        </div>
      </div>
      <div id="footer2">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-4 text-center">
              <p>Desenvolvido por © <a href="https://tweezer.com.br" target="_Blank">Tweezer | 43.112.901/0001-88</a> </p>  
            </div>
            <div className="col-12 col-md-8">
              <p style={{fontSize: 10, color: "gray"}}><b>Este site não é afiliado ao Facebook ou a qualquer entidade do Facebook. Depois que você sair do Facebook, a responsabilidade não é deles e sim do nosso site.</b> Fazemos todos os esforços para indicar claramente e mostrar todas as provas do produto e usamos resultados reais. Nós não vendemos o seu e-mail ou qualquer informação para terceiros. Jamais fazemos nenhum tipo de spam. Se você tiver alguma dúvida, sinta-se à vontade para usar o link de contato e falar conosco em horário comercial de Segunda a Sextas das 09h00 ás 18h00. Lemos e respondemos todas as mensagens por ordem de chegada.</p>  
            </div>
          </div>
        </div>
      </div>

      <div className="divBtnWhatsApp">
        <button className="btnWhatsApp" onClick={() => window.open("https://web.whatsapp.com/send/?phone=5555997190882&text=Ol%C3%A1,%20gostaria%20de%20falar%20sobre%20o%20SMART-IRIS", "_Blank")}>
          <FaWhatsapp size="2.2em" color="#fff"/>
        </button>
      </div>

      <Modal
        isOpen={modalShow}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalShow(!modalShow)}}
        style={customStylesModal}
        contentLabel="Oferta"
      >
        <button className="btnCloseModal" onClick={() => {setModalShow(!modalShow)}}>✘</button>
        <div className="form-group text-center">
          <br />
          <h4 style={{color: 'initial', marginBottom: '2rem'}}><b>💥BLACK FRIDAY!💥</b></h4>
          <p style={{color: 'initial', paddingBottom: 20}}>Aproveite <b style={{color: "#09c4a9"}}> + 15% OFF</b> como o <b style={{color: "#09c4a9"}}>CUPOM</b>:</p>
          <div style={{margin: 20}}><span className="cupom">BLACKFRIDAY</span></div>
          <br />
          <p style={{color: 'initial', marginBottom: 0}}>Kit Padrão: De <b style={{textDecoration: 'line-through'}}>R$ 980,00</b> por <b style={{color: "#09c4a9", fontSize: 22}}> R$ 756,50</b></p>
          <p style={{color: 'initial', paddingBottom: 0}}>Kit Plus: De <b style={{textDecoration: 'line-through'}}>R$ 1.190,00</b> por <b style={{color: "#09c4a9", fontSize: 22}}> R$ 926,50</b></p>
          {/*<a href="#bodyPlans" onClick={() => {setModalShow(!modalShow)}} className="btn btn-default" style={{padding: "15px 35px", marginTop: 15}}>
            Ver Oferta
          </a>*/}
          <div className="text-center" style={{paddingTop: 20, marginBottom: -18}}>
            <span style={{color: 'initial', fontSize: 14}} className="text-center obs">* Válido somente até dia 24 de novembro de 2023 às 23:59h</span>
            {/*<span style={{color: 'initial', fontSize: 14}} className="text-center obs">* Prorrogado até a Cyber Monday, 27 de novembro de 2023 às 23:59h</span>*/}
          </div>
        </div>
      </Modal>
    </div>
  );
 
}