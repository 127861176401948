import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Index from './pages/Index';
import Obrigado from './pages/Obrigado';
import Privacidade from './pages/Politicas/Privacidade';
import Termos from './pages/Politicas/Termos';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/smart-iris-pro" exact component={Index} />
        <Route path="/smart-iris-g1" exact component={Index} />
        <Route path="/obrigado" exact component={Obrigado} />
        <Route path="/politicas/privacidade" exact component={Privacidade} />
        <Route path="/politicas/termos" exact component={Termos} />
        <Redirect to="/" /> {/*Qualquer outra rota redireciona para a index*/}
      </Switch>
    </BrowserRouter>
  );
}